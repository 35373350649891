<template>
  <div>
    <div class="page-list personnelfiles flex-1" v-if="showType==1">
      <a-row class="filter-wrap">
        <a-col :lg="8" :md="24" :sm="24" :xs="24" style="margin-left:0px;" class="mgb-20">
          <a-button type="primary" class="table-btn-clk" style="margin-right:19px;margin-left: 0px;" @click="addUser"><i class="meiye-icon meiye-routeicon-add-copy" style="margin-right:5px"></i> 新建</a-button>
        </a-col>
      </a-row>
      <a-table :customRow="rowClick" class="table-manage flex-1" :scroll="{ x: '100%',y:$utils.getfullHeight(306)}" @change="tableChanged" :pagination="table.pagination" row-key="member_id" :loading="table.loading" :columns="config.customer.columns" :data-source="table.tableData">
        <template slot="_1" slot-scope="action, record">
          <a-tooltip>
            <template slot="title">
              {{record.scheme_name}}
            </template>
            {{record.scheme_name}}
          </a-tooltip>
        </template>
        <template slot="_5" slot-scope="action, record">
          <span class="theme-color" v-if="record.status == 1">{{record.status_text}}</span>
          <span v-else>{{record.status_text}}</span>
        </template>
        <template slot="_8" slot-scope="action, record">
          <a-button size="small" class="mgr-12 smallbtn" @click.stop="toDetail(record,1)">查看</a-button>
          <a-button size="small" class="mgr-12 smallbtn" v-if="record.status!=2" @click.stop="editUser(record,2)">编辑</a-button>
          <a-button size="small" class="mgr-12 smallbtn" @click.stop="copy(record)">复制</a-button>
        </template>
      </a-table>
    </div>
    <template v-else-if="showType==2">
      <Add @onCancel="onCancel"></Add>
    </template>
    <template v-else-if="showType==3">
      <Detail @onCancel="onCancel"></Detail>
    </template>
  </div>

</template>
<script> 

import Add from './add.vue'
import Detail from './detail.vue'
import config from './config'
import { partnerIndex, partnerCopy } from '@/api/partner'

export default {
  components: { Add, Detail },
  data() {
    let that = this
    return {
      config,
      showType: 1,
      query: {
        per_page: 10,
        page: 1,
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
          itemRender: that.itemRender
        },
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link" ><i class='meiye-icon meiye-fanghui'></i ></a>;
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link" ><i class='meiye-icon meiye-fanghui meiye-fanghui2' > </i ></a>;
      }
      return originalElement;
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            this.toDetail(record, 1)
          }
        }
      }
    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    // 获取列表
    getList() {
      let self = this
      self.table.loading = true
      return partnerIndex(self.query).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data
        self.table.tableData = data
        self.table.pagination = Object.assign({}, { pageSize: this.query.per_page, current: this.query.page }, {
          total: total
        })
      }).finally(r => {
        self.table.loading = false
      })
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { per_page: Number(pageSize), page: Number(current) })
      this.getList()
    },
    // 复制
    copy(item) {
      let self = this
      let titles = item.scheme_name
      self.$confirm({
        title: '复制',
        wrapClassName: 'modal_copys',
        // icon: 'exclamation-circle',
        icon: () => this.$createElement('a-icon', {
          props: {
            type: 'exclamation-circle',
            theme: 'filled',
            class: 'idddds',
            style: "{color: 'red'}"
          },
        }),

        closable: true,
        // content: '您将复制' + item.scheme_name + '，是否确认复制',
        content: h =>
          <div>
            您将复制 <span class='baseColor_copy' >{titles}</span> ，是否确认复制
          </div>
        ,
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          partnerCopy({
            id: item.id
          }).then((res) => {
            self.getList()
            console.log('res :>> ', res);
          })
        },
        onCancel() {
          console.log('111111111 :>> ', 111111111);
        },
      });

    },
    onCancel() { this.showType = 1; this.getList() },
    //查看详情
    toDetail(e, i) {
      this.showType = 3;
      this.$router.push({ path: 'partnerMenuSetting', query: { type: 3, id: e.id } })
    },
    // 编辑
    editUser(e, i) {
      this.showType = 2;
      this.$router.push({ path: 'partnerMenuSetting', query: { type: 2, id: e.id } })
    },
    // 添加
    addUser() {
      this.showType = 2;
      this.$router.push({ path: 'partnerMenuSetting', query: { type: 1 } })
    },
  },

}
</script>
<style lang="less" scoped>
.baseColor_copy {
  color: @baseColor11;
}
.page-list {
  background: #fff;
  min-height: calc(100vh - 88px);
  // margin: 18px;
  border-radius: 2px;
}
/deep/.ant-table-fixed-left,
/deep/.ant-table-fixed-right {
  top: 0;
}
.meiye-fanghui {
  font-size: 17px;
}
.meiye-fanghui2 {
  display: inline-block;
  transform: rotate(180deg);
  margin-top: -1px;
}
</style>